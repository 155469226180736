import React, { useEffect, useState } from 'react'

import Layout from '../../components/layout'
import SEO from '../../components/seo'
import GettingStartedContent from '../../components/HelpCenter/GettingStarted/GettingStarted'
import { Faq } from '../../components/FAQs/faqs-list-interface'
import { Breadcrumb } from '../../components/Breadcrumbs/Breadcrumbs'
import { HELP_CENTRE, HC_GAME_RULES } from '../../components/internal-links'
import {
  generateBreadcrumbsSchema,
  generateFaqSchema,
  generateOrgSchema,
  generatePokerTutorialsVideoSchema,
  generateRummyTutorialsVideoSchema,
  generateWebsiteSchema,
} from '../../lib/generate-schema'
import {
  pokerOneLinksStaticPage,
  rummyOneLinksStaticPage,
} from '../../components/one-links'
import { HOMEPAGE_BREADCRUMB_TITLE } from '../../utils/constnst.utils'

const breadcrumbs: Breadcrumb[] = [
  {
    title: HOMEPAGE_BREADCRUMB_TITLE,
    url: '/',
  },
  {
    title: 'Help Centre',
    url: HELP_CENTRE,
  },
  {
    title: 'Game Rules',
    url: HC_GAME_RULES,
  },
]

const GameRules: React.FC = () => {
  const [gameRulesFaqsSt, setGameRulesFaqsSt] = useState<Faq[]>([])

  useEffect(() => {
    import('../../components/FAQs/faqs-list').then(({ gameRulesFaqs }) =>
      setGameRulesFaqsSt(gameRulesFaqs)
    )
  }, [])

  return (
    <Layout
      showBreadcrumbs
      breadcrumbs={breadcrumbs}
      pokerOneLink={pokerOneLinksStaticPage}
      rummyOneLink={rummyOneLinksStaticPage}
    >
      <SEO
        title="Contest Rules: Get Help on Contest Rules for Games on Mega App"
        description="Visit our game rules help centre for answers to queries on rules on various games such as poker, rummy, carrom, pool, among others. Click to read more!"
        organizationSchema={generateOrgSchema()}
        websiteSchema={generateWebsiteSchema()}
        breadcrumbsSchema={generateBreadcrumbsSchema(breadcrumbs)}
        faqSchema={generateFaqSchema(gameRulesFaqsSt)}
        videoSchema={[
          ...generatePokerTutorialsVideoSchema(),
          ...generateRummyTutorialsVideoSchema(),
        ]}
      />
      <GettingStartedContent pageIndex={4} faqs={gameRulesFaqsSt} />
    </Layout>
  )
}

export default GameRules
